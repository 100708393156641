import { default as _91_46_46_46app_932MeKwZqTPTMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/[...app].vue?macro=true";
import { default as indexOJt5cP9NeeMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/accountant-directory/index.vue?macro=true";
import { default as index1WnJpvHjTFMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/audit/index.vue?macro=true";
import { default as _91slug_93IdYwkshBh7Meta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93yPFTto5m0RMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/blog/category/[slug].vue?macro=true";
import { default as index7GgIl7aozAMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/blog/index.vue?macro=true";
import { default as _91slug_93OZ1xYRWL3UMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/company/[slug].vue?macro=true";
import { default as _91slug_93IhOYRG6lOKMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/glossary/[slug].vue?macro=true";
import { default as indexLMzMsdH08sMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/glossary/index.vue?macro=true";
import { default as _91slug_93amVC3DbAChMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/tutorials/[slug].vue?macro=true";
import { default as indexLDoLlHBPDmMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/tutorials/index.vue?macro=true";
import { default as component_45stubWF2txcjxLwMeta } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubWF2txcjxLw } from "/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "app",
    path: "/:app(.*)*",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/[...app].vue")
  },
  {
    name: "accountant-directory",
    path: "/accountant-directory",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/accountant-directory/index.vue")
  },
  {
    name: "audit",
    path: "/audit",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/audit/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/blog/[slug].vue")
  },
  {
    name: "blog-category-slug",
    path: "/blog/category/:slug()",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/blog/category/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/blog/index.vue")
  },
  {
    name: "company-slug",
    path: "/company/:slug()",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/company/[slug].vue")
  },
  {
    name: "glossary-slug",
    path: "/glossary/:slug()",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/glossary/[slug].vue")
  },
  {
    name: "glossary",
    path: "/glossary",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/glossary/index.vue")
  },
  {
    name: "tutorials-slug",
    path: "/tutorials/:slug()",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/tutorials/[slug].vue")
  },
  {
    name: "tutorials",
    path: "/tutorials",
    component: () => import("/codebuild/output/src1887789905/src/cybooks-marketing-boilerplate/pages/tutorials/index.vue")
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubWF2txcjxLw
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/posts-sitemap.xml",
    component: component_45stubWF2txcjxLw
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/glossary-sitemap.xml",
    component: component_45stubWF2txcjxLw
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/companies_1-sitemap.xml",
    component: component_45stubWF2txcjxLw
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/companies_2-sitemap.xml",
    component: component_45stubWF2txcjxLw
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/companies_3-sitemap.xml",
    component: component_45stubWF2txcjxLw
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/companies_4-sitemap.xml",
    component: component_45stubWF2txcjxLw
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/companies_5-sitemap.xml",
    component: component_45stubWF2txcjxLw
  },
  {
    name: component_45stubWF2txcjxLwMeta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubWF2txcjxLw
  }
]
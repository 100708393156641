// plugins/katex.ts
import { defineNuxtPlugin } from '#app'
import type { Plugin } from 'vue'
import 'katex/dist/katex.min.css'

// Preload KaTex
const katexPromise = import('katex')

const plugin = defineNuxtPlugin(async (nuxtApp) => {
  // Wait for KaTex to load
  const katex = await katexPromise

  nuxtApp.vueApp.directive('katex', {
    mounted(el: HTMLElement, binding: any) {
      if (!binding.value) return

      try {
        el.innerHTML = katex.default.renderToString(binding.value, {
          throwOnError: false,
          displayMode: true,
          strict: false,
          trust: true,
          macros: {
            '\\f': '#1f(#2)',
          },
        })
      } catch (error) {
        console.error('KaTex rendering error:', error)
        el.innerHTML = `<span style="color: red">Error rendering equation: ${error instanceof Error ? error.message : 'Unknown error'}</span>`
      }
    },
    updated(el: HTMLElement, binding: any) {
      if (!binding.value) return

      try {
        el.innerHTML = katex.default.renderToString(binding.value, {
          throwOnError: false,
          displayMode: true,
          strict: false,
          trust: true,
          macros: {
            '\\f': '#1f(#2)',
          },
        })
      } catch (error) {
        console.error('KaTex rendering error:', error)
        el.innerHTML = `<span style="color: red">Error rendering equation: ${error instanceof Error ? error.message : 'Unknown error'}</span>`
      }
    },
  })
})

export default plugin

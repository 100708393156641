<template>
    <div class="flex flex-col justify-center">
      <ResourceMenuItem
        v-for="item in menuItems"
        :key="item.title"
        v-bind="item"
      />
    </div>
  </template>
  
  <script>
  import ResourceMenuItem from './ResourceMenuItem.vue'
  
  export default {
    name: 'ResourceMenuList',
    components: {
      ResourceMenuItem
    },
    data() {
      return {
        menuItems: [
          {
            icon: "M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59",
            title: "Resource Center",
            description: "Explore events, guides, and downloadable tools related to your topics of interest.",
            link: "#",
            linkType: "a"
          },
          {
            icon: "M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59",
            title: "Blog",
            description: "Stay up-to-date with the latest articles to improve your financial operations.",
            link: "/blog",
            linkType: "nuxt-link"
          },
          {
            icon: "M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59",
            title: "Help Center",
            description: "Learn how to use Cybooks with bite-sized tutorials, FAQs, and best practices.",
            link: "https://support.cybooks.com.cy/en/",
            linkType: "nuxt-link",
            target: "_blank",
            rel: "noopener noreferrer"
          }
        ]
      }
    }
  }
  </script>
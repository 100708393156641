<template>
  <section
    class="w-[900px] relative mt-3 z-50 flex justify-start items-end px-6 pt-6 pb-4 bg-white rounded-md border border-gray-200 border-solid shadow-lg max-md:px-5"
  >
    <div class="arrow-top" :style="{ left: `${arrowLeftOffset}px` }"></div>
    <div class="flex gap-6 h-full justify-center">
      <HeaderMenuResourceMenuList class="w-5/12 border-r" />
      <div class="flex gap-2.5">
        <HeaderMenuResourcesTools />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MegaMenuResources',
  props: {
    arrowLeftOffset: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped>
.arrow-top {
  position: absolute;
  top: -8px;
  width: 16px;
  height: 8px;
  overflow: hidden;
  transform: translateX(-50%);
}

.arrow-top::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 50%;
  width: 14px;
  height: 14px;
  background: white;
  border-top: 1px solid #e5e7eb;
  border-left: 1px solid #e5e7eb;
  transform: translateX(-50%) rotate(45deg);
}
</style>

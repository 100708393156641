<template>
  <div
    class="group items-center relative flex gap-x-6 rounded-lg py-2 px-3 hover:bg-gray-50"
  >
    <!-- 
    <div
      class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50"
    >
      <svg
        class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path stroke-linecap="round" stroke-linejoin="round" :d="icon"></path>
      </svg>
    </div>
    -->
    <div class="gap-3">
      <nuxt-link
        :to="link"
        :target="target"
        :rel="rel"
        class="text-base font-semibold text-gray-900"
      >
        {{ title }}
        <span class="absolute inset-0"></span>
      </nuxt-link>
      <p class="text-xs font-medium text-gray-500">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourceMenuItem',
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: '',
    },
    rel: {
      type: String,
      default: '',
    },
  },
}
</script>

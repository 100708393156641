<script setup>
const error = useError()
</script>
<template>
  <div>
    <NuxtLayout>
      <div class="h-screen text-white bg-violet-900">
        <base-header />

        <section
          class="flex flex-col justify-center items-center w-full max-md:max-w-full h-full -mt-24"
        >
          <div class="flex flex-col max-w-[660px] max-md:max-w-full">
            <template v-if="error.statusCode === 404">
              <svg
                width="372"
                height="151"
                viewBox="0 0 372 151"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M69.94 106.63H1.48L1 82.64C18.88 62.64 22.41 43.82 22.41 11.12V1.23999H51.11V6.40999C51.35 39.82 45.94 64.52 33.47 79.11V81.46H69.94V3.58999H98.41V81.46H113.41V106.63H98.41V147.34H69.94V106.63Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M134.4 77.93V72.76C135.34 16.77 150.16 1 183.57 1H191.1C230.39 1 239.33 25 240.27 72.76V77.93C239.33 133.93 224.5 149.69 191.1 149.69H183.57C144.28 149.69 135.34 125.69 134.4 77.93Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M212.74 63.3501C211.57 31.3501 203.57 25.4701 187.33 25.2401C168 25.0001 162.4 35.5901 161.69 63.3501H212.74Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M213 85.9299H161.69C162.87 119.57 170.87 125.69 187.33 125.46C206.63 125.22 212 114.63 213 85.9299Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M326.84 106.63H258.38L257.91 82.63C275.79 62.63 279.32 43.81 279.32 11.11V1.23999H308V6.40999C308.23 39.82 302.82 64.52 290.36 79.11V81.46H326.82V3.58999H355.29V81.46H370.34V106.63H355.31V147.34H326.84V106.63Z"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>

              <h1 class="text-2xl">Oops, this page doesn't exist</h1>
              <p>
                Oops, this page doesn't exist The link that led you here is
                called a “broken link.”
              </p>
            </template>
            <template v-else>
              <svg
                width="372"
                height="151"
                viewBox="0 0 372 151"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M69.94 106.63H1.48L1 82.64C18.88 62.64 22.41 43.82 22.41 11.12V1.23999H51.11V6.40999C51.35 39.82 45.94 64.52 33.47 79.11V81.46H69.94V3.58999H98.41V81.46H113.41V106.63H98.41V147.34H69.94V106.63Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M134.4 77.93V72.76C135.34 16.77 150.16 1 183.57 1H191.1C230.39 1 239.33 25 240.27 72.76V77.93C239.33 133.93 224.5 149.69 191.1 149.69H183.57C144.28 149.69 135.34 125.69 134.4 77.93Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M212.74 63.3501C211.57 31.3501 203.57 25.4701 187.33 25.2401C168 25.0001 162.4 35.5901 161.69 63.3501H212.74Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M213 85.9299H161.69C162.87 119.57 170.87 125.69 187.33 125.46C206.63 125.22 212 114.63 213 85.9299Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M326.84 106.63H258.38L257.91 82.63C275.79 62.63 279.32 43.81 279.32 11.11V1.23999H308V6.40999C308.23 39.82 302.82 64.52 290.36 79.11V81.46H326.82V3.58999H355.29V81.46H370.34V106.63H355.31V147.34H326.84V106.63Z"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
              <h1 class="text-4xl mt-10 mb-5">Oops, this page doesn't exist</h1>
              <p class="text-lg">
                Oops, this page doesn't exist The link that led you here is
                called<br />
                a “broken link.”
              </p>
            </template>
          </div>
        </section>
      </div>

      <BaseFooter />
    </NuxtLayout>
  </div>
</template>

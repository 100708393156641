<template>
  <div class="flex flex-col justify-center">
    <ResourceMenuItem
      v-for="item in menuItems"
      :key="item.title"
      v-bind="item"
    />
  </div>
</template>

<script>
import ResourceMenuItem from './ResourceMenuItem.vue'

export default {
  name: 'ResourceMenuList',
  components: {
    ResourceMenuItem,
  },
  data() {
    return {
      menuItems: [
        {
          icon: 'M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59',
          title: 'Accounting Software',
          description: 'For small businesses in Cyprus.',
          link: '/products/accounting-software',
          linkType: 'nuxt-link',
        },
        {
          icon: 'M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59',
          title: 'Payroll Software',
          description: 'For businsses in Cyprus',
          link: '/products/payroll',
          linkType: 'nuxt-link',
        },
        {
          icon: 'M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59',
          title: 'Practice Management',
          description: 'For accountants to manage their firm',
          link: '/products/practice-management',
          linkType: 'nuxt-link',
        },
        {
          icon: 'M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59',
          title: 'Agency Management',
          description: 'Manage your agency with confidence',
          link: '/products/agency-management',
          linkType: 'nuxt-link',
        },
      ],
    }
  },
}
</script>

<template>
  <nuxt-link :to="url" :title="text" class="flex" :class="linkClass">
    <span>{{ text }}</span>
  </nuxt-link>
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: 'Button',
  },
  url: {
    type: String,
    default: '/',
  },
  variant: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'md',
  },
})

const linkClass = computed(() => {
  if (props.variant === 'primary') {
    return 'gap-3 rounded-md bg-violet-700 px-5 py-3 text-base font-semibold text-white shadow-sm hover:bg-violet-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-900'
  } else if (props.variant === 'secondary') {
    return 'gap-3 rounded-md bg-gray-25 px-5 py-3 text-base font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:border-white'
  } else if (props.variant === 'link') {
    return 'gap-3 rounded-md px-5 py-3 text-base font-semibold text-white opacity-60 shadow-sm hover:border hover:border-violet-900 hover:opacity-100'
  } else if (props.variant === 'tertiary') {
    return 'gap-3 rounded-md px-5 py-3 text-base font-semibold text-white shadow-sm border border-2 border-violet-700 hover:bg-pink-600 hover:border-pink-600'
  } else if (props.variant === 'black') {
    return 'gap-3 rounded-md px-5 py-3 text-base font-semibold text-white shadow-sm bg-gray-900 border border-2 border-gray-900 hover:bg-gray-800 hover:border-gray-800'
  } else {
    return 'flex overflow-hidden gap-1.5 justify-center items-center text-base font-semibold leading-none'
  }
})
</script>
